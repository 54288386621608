.hexawalk{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.hexawalk__grid{
    display: grid;
    width: 350px;
    height: 350px;
    grid-template-columns: 1fr 2fr 1fr 2fr 1fr 2fr 1fr;
}

.hexawalk__dialog{
    position: absolute;
    top: 45vh;
    z-index: 2000;
    width: 350px;
    text-align: center;
}

.hexawalk__details{
    margin-top: 0.5em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 350px;
}