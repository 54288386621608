.sizeselector{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.sizeselector__buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.home__padding{
    height: 0.5em;
}